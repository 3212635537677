import Vue, { PropType } from 'vue'
import focusableMixin from '@/pages/ProfileSettings/focusableMixin'
import responseMixin from '@/mixins/responseMixin'
import Channel from '@/models-ts/user/Channel'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import { Channel as Channels } from '@/constants/user/channels'
import { getSocialLink } from '@/utils-ts/strings'
import { saveCustomerChannelsProfile } from '@/api/users'

export default Vue.extend<any, any, any, any>({
  mixins: [focusableMixin, responseMixin],
  props: {
    role: Number,
    channels: {
      type: Array as PropType<Array<Channel>>,
      default: true,
    }
  },
  data () {
    return {
      editMode: false,
      saving: false,
      form: [],
      facebook: '',
      linkedIn: '',
      twitter: '',
      editableFields: ['contacts'],
      Channels,
    }
  },
  computed: {
    wasUpdated () {
      return this.facebook !== this.getChannel(Channels.Facebook) ||
        this.linkedIn !== this.getChannel(Channels.LinkedIn) ||
        this.twitter !== this.getChannel(Channels.Twitter)
    },
  },
  watch: {
    channels: {
      handler () {
        if (this.channels) {
          this.initForm()
        }
      },
      immediate: true
    }
  },
  methods: {
    getSocialLink,
    getChannel (type: Channels) {
      const channels = this.channels as Array<Channel>
      return channels.find(ch => ch.type === type && ch.customerType === this.role)?.value || ''
    },
    initForm () {
      this.facebook = this.getChannel(Channels.Facebook)
      this.linkedIn = this.getChannel(Channels.LinkedIn)
      this.twitter = this.getChannel(Channels.Twitter)
    },
    onCancelClick () {
      this.editMode = false
      this.initForm()
    },
    async onFormSubmit () {
      const isValid = await Promise.all((this.$refs.experienceItem || []).map((item: any) => item.validate()))
      if (isValid.every(Boolean)) {
        try {
          this.saving = true
          const channels = await notifiableRequest({
            request: async () => {
              const channels = [
                { type: Channels.Facebook, value: this.facebook.trim(), customer_type: this.role },
                { type: Channels.LinkedIn, value: this.linkedIn.trim(), customer_type: this.role },
                { type: Channels.Twitter, value: this.twitter.trim(), customer_type: this.role },
              ]
              return saveCustomerChannelsProfile(channels)
            },
            title: 'Social media',
            successText: 'Your social media accounts have been successfully updated.',
            failureText: 'Error updating your information. Please try again.'
          })
          this.form = channels.map(Channel.fromServer)
          this.$emit('update', this.form)
          this.editMode = false
        } catch (e) {
          console.error(e)
        } finally {
          this.saving = false
        }
      }
    },
  },
})
