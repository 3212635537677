import Vue from 'vue'

export default Vue.extend<any, any, any>({
  data () {
    return {
      editableFields: [],
    }
  },
  mounted () {
    const selector = (this.$route.hash || '').slice(1)
    if (this.$route.hash && this.editableFields.includes(selector)) {
      this.editMode = true
      if (this.onAddClick) {
        this.onAddClick()
      }
      this.$nextTick(() => this.setFocusByAnchor())
    }
  },
  methods: {
    setFocusByAnchor () {
      if (this.$route.hash) {
        const el = this.$el.querySelector(this.$route.hash)
        if (el) {
          const focusableEl = el.querySelector('input, textarea')
          if (focusableEl) {
            this.$nextTick(() => {
              focusableEl.focus()
            })
          }
        }
      }
    },
  },
})
